.hb-input {
  --background: var(--ion-color-light);
  --color: #fff;
  --placeholder-color: #ddd;
  --placeholder-opacity: 0.8;
  border-radius: 8px;
  --border-radius: 8px;
  --padding-start: 16px;
  --padding-end: 16px;
  box-sizing: border-box;
  max-width: calc(100% - 32px);
}
