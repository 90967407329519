// Hide all elements
body.barcode-scanner-active {
    visibility: hidden;
    --background: transparent;
    --ion-background-color: transparent;
  }
  
  // Show only the barcode scanner modal
  .barcode-scanner-modal {
    visibility: visible;
  }
  
  @media (prefers-color-scheme: dark) {
    .barcode-scanner-modal {
      --background: transparent;
      --ion-background-color: transparent;
    }
  }