// Основные цвета
.color {
  &-pink {
    color: #ff41b2 !important;
    --ion-color-base: #ff41b2;

    &-checked {
      --background-checked: #ff41b2;
      --border-color-checked: #ff41b2;
    }
  }

  &-medium {
    color: var(--ion-color-medium);
  }

  &-white {
    color: #ffffff; // Заменил "white" на HEX для консистентности
  }

  &-dark {
    color: var(--ion-color-dark) !important;
    --color: var(--ion-color-dark) !important;
  }

  // Универсальные цвета
  &-primary {
    --color: var(--ion-color-primary) !important;
  }

  &-danger {
    --color: var(--ion-color-danger) !important;
    color: var(--ion-color-danger) !important;
  }

  &-warning {
    --color: var(--ion-color-warning) !important;
  }

  &-success {
    --color: var(--ion-color-success) !important;
  }
}

// Фоновые цвета
.bg {
  &-pink {
    background: #ff41b2;

    &-ion {
      --background: #ff41b2;

      &::part(native) {
        color: #ffffff;
      }
    }

    &-light {
      background: var(--ion-color-light);

      &-ion {
        --background: var(--ion-color-light);

        &::part(native) {
          color: var(--ion-color-dark);
        }
      }
    }
  }

  &-dark {
    background: var(--ion-color-light); // Предполагаем, что "light" используется для "dark"-фона
  }

  &-light {
    background: var(--ion-color-light);
  }
}

// Ion-specific цвета
.ion-color-pink {
  --ion-color-base: var(--ion-color-pink);
  --ion-color-base-rgb: var(--ion-color-pink-rgb);
  --ion-color-contrast: var(--ion-color-pink-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink-shade);
  --ion-color-tint: var(--ion-color-pink-tint);
}
