.flex {
    display: flex!important;

    &-1 {
        flex: 1;
    }

    &-3 {
      flex: 3;
    }

    &-row {
        display: flex!important;
        width: 100%;
    }

    &-direction-column {
      flex-direction: column;
    }

    &-wrap {
      flex-wrap: wrap;
    }

    &-space-between {
      justify-content: space-between;
    }
}
